import React from "react";
import { Link } from "react-router-dom";
import './main.css';

class Navbar extends React.Component {
    render() {
	return (
	    <nav>
		<Link to="/">Home</Link>
		<Link to="/projects">Projects</Link>
		<Link to="/about">About</Link>
	    </nav>
	);
    }
}

export default Navbar;
