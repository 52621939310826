import React from 'react';

function About() {
    return (
	<React.Fragment>
	    <h1>About page</h1>
	    <p>This website is intended for showing off a couple projects i've made, or for messing with when i'm bored.</p>
	    <br/>
	    <br/>
	    <p>If you've made it here you probably know how to contact me, if not you can email me at <a href="mailto:boss@tehbox.org">boss@tehbox.org</a></p>
	</React.Fragment>
    );
}

export default About;
