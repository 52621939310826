import React from 'react';

import YATwm from '../images/YATwm.png';

function Projects() {
	return (
	    <React.Fragment>
		<h1>My projects</h1>
		<div className = "row">
		    <div className = "col w50">
			<div className = "card">
			    <h2 className = "centered"><a href="https://git.tehbox.org/cgit/boss/YATwm.git">YATwm</a></h2>
			</div>
			<div className = "card">
			    <p>YATwm is a tiling window manager written in c++. The goal is to be like i3, but have a few extra features that I would find usefull</p>
			    <p>Currently it is almost in a useable state, it just has a couple bugs, and is missing an external control program, and scratch windows</p>
			    <br/>
			    <br/>
			    <p>The image shown is a picture of what YATwm <em>can</em> look like, with my config at the time. (ignore the black corners on emacs, that was a compositor fault).</p>
			</div>
		    </div>
		    <div className = "col w50">
			<div className = "card">
			    <img src = {YATwm}/>
			</div>
		    </div>
		</div>
	    </React.Fragment>
	);
}
export default Projects;
