import React from 'react';

function PageNotFound() {
	return (
		<React.Fragment>
			<h1>404 page</h1>
			<div className = "main-col">
				<p>uh oh page doesn't exist</p>
			</div>
		</React.Fragment>
	);
}

export default PageNotFound;
