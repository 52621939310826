import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import Home from "./pages/home";
import About from "./pages/about";
import Projects from "./pages/projects";
import PageNotFound from "./pages/pageNotFound";
//import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    (
	<BrowserRouter>
	    <Routes>
		<Route path="/" element={<App />}>
		    <Route index element={<Home />} />
		    <Route path="about" element={<About />} />
		    <Route path="projects" element={<Projects />} />
		    <Route path="*" element={<PageNotFound />} />
		</Route>
	    </Routes>
	</BrowserRouter>
    ),
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
