import React from 'react';
import Layout from './layout';

const App = () => {
  return (
    <Layout/>
  );
}

export default App;
