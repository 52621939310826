import React from 'react';

function Home() {
	return (
		<React.Fragment>
		    <h1>Home, woo!!!</h1>
		    <div className = "col w50">
			<p>I just created this website to test my domain and vps</p>
			<p>A bunch more text so that you can see my epic flexbox layout and marvel at how well the columns fit together, it truely is a wonder of software engineering. I expect you to think of it any time you see a boring website.</p>
		    </div>
		    <div className = "col w50">
			<p>Second column of text, woah so fancy</p>
			<p>I'm bored for this side, have some lorem ipsum</p>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum lorem est, ullamcorper sed ultrices eget, tincidunt nec dui. Sed egestas molestie eros, sed finibus diam ultricies in. Curabitur vel nisi et ante posuere sollicitudin. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
		    </div>
		</React.Fragment>
	);
}
export default Home;
